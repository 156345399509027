import { LINKS } from '../constants/constants';

const useIsCheckoutPage = () => {
  if (typeof window !== 'undefined') {
    const path = window.location.pathname;
    const normalizedPath = path.replace(/^\/[a-z]{2,3}\//, '/');

    return [LINKS.SIGN_UP, LINKS.SUBSCRIPTION_PRICING, LINKS.SUBSCRIPTION_CARD].includes(normalizedPath);
  }

  return false;
};

export default useIsCheckoutPage;
