import { FC, memo, PropsWithChildren, ReactNode } from 'react';
import { ActionsContainer, Header } from './styled';
import LogoSSR from '../components/LogoSSR';
import LanguageSelect from 'components/LanguageSelect';
import Hidden from 'components/ui/Hidden';

type MainHeaderProps = {
  children?: ReactNode;
  linkTo: string;
  showLanguagePicker?: boolean;
  facelift?: boolean;
  miniGenerator?: boolean;
  qrDownload?: boolean;
  miniGeneratorV2?: boolean;
};

const MainHeader: FC<PropsWithChildren<MainHeaderProps>> = ({
  children,
  linkTo,
  showLanguagePicker = true,
  facelift = false,
  miniGenerator = false,
  qrDownload = false,
  miniGeneratorV2 = false,
}) => {
  return (
    <Header
      qrDownload={qrDownload}
      facelift={facelift}
      miniGenerator={miniGenerator}
      className={miniGeneratorV2 ? 'mini-generator-v2' : ''}
    >
      <LogoSSR linkTo={linkTo} />
      <ActionsContainer>
        {children}
        {showLanguagePicker && !facelift ? (
          <Hidden mdDown>
            <LanguageSelect isCompact />
          </Hidden>
        ) : null}
      </ActionsContainer>
    </Header>
  );
};

export default memo(MainHeader);
